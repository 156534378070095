import React from "react";

const LocationCurrent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      d="M11 8C9.19995 8 7.69995 9.5 7.69995 11.3C7.69995 13.1 9.19995 14.6 11 14.6C12.8 14.6 14.3 13.1 14.3 11.3C14.3 9.4 12.8 8 11 8ZM11 13.2C9.99995 13.2 9.09995 12.3 9.09995 11.3C9.09995 10.3 9.99995 9.4 11 9.4C12 9.4 12.9 10.3 12.9 11.3C12.9 12.3 12 13.2 11 13.2Z"
      fill="currentColor"
    />
    <path
      d="M22 10.6008H19.2C18.9 6.60078 15.7 3.40078 11.7 3.10078V0.300781H10.3V3.00078C6.3 3.30078 3.1 6.50078 2.8 10.5008H0V12.0008H2.8C3.1 16.0008 6.3 19.2008 10.3 19.5008V21.7008H11.7V19.5008C15.7 19.2008 18.9 16.0008 19.2 12.0008H22V10.6008ZM11 18.1008C7.2 18.1008 4.1 15.0008 4.1 11.2008C4.1 7.40078 7.2 4.30078 11 4.30078C14.8 4.30078 17.9 7.40078 17.9 11.2008C17.9 15.1008 14.8 18.1008 11 18.1008Z"
      fill="currentColor"
    />
  </svg>
);

export default LocationCurrent;
