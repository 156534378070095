import React from "react";

const MenuHamburger = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 22.5H24" stroke="currentColor" stroke-width="3" />
    <path d="M0 1.5H24" stroke="currentColor" stroke-width="3" />
    <path d="M0 12H24" stroke="currentColor" stroke-width="3" />
  </svg>
);

export default MenuHamburger;
