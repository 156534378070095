import React from "react";

const Motorycle = () => (
  <svg
    width="57"
    height="41"
    viewBox="0 0 57 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1807 40.04C8.30175 40.04 6.46502 39.4829 4.90275 38.439C3.34048 37.3951 2.12285 35.9114 1.40381 34.1755C0.68478 32.4396 0.496649 30.5295 0.863208 28.6867C1.22977 26.8439 2.13455 25.1511 3.46315 23.8225C4.79175 22.4939 6.48449 21.5891 8.32731 21.2226C10.1701 20.856 12.0803 21.0442 13.8162 21.7632C15.5521 22.4822 17.0358 23.6999 18.0796 25.2621C19.1235 26.8244 19.6807 28.6611 19.6807 30.54C19.678 33.0588 18.6763 35.4736 16.8953 37.2546C15.1142 39.0357 12.6994 40.0374 10.1807 40.04ZM10.1807 23.04C8.69731 23.04 7.24726 23.4799 6.01389 24.304C4.78052 25.1281 3.81923 26.2995 3.25157 27.6699C2.68391 29.0404 2.53539 30.5484 2.82478 32.0032C3.11417 33.4581 3.82847 34.7945 4.87737 35.8433C5.92626 36.8922 7.26263 37.6065 8.71749 37.8959C10.1723 38.1853 11.6803 38.0368 13.0508 37.4691C14.4212 36.9015 15.5926 35.9402 16.4167 34.7068C17.2408 33.4735 17.6807 32.0234 17.6807 30.54C17.6807 28.5509 16.8905 26.6433 15.484 25.2367C14.0774 23.8302 12.1698 23.04 10.1807 23.04Z"
      fill="currentColor"
    />
    <path
      d="M47.1807 40.04C45.3017 40.04 43.465 39.4829 41.9028 38.439C40.3405 37.3951 39.1228 35.9114 38.4038 34.1755C37.6848 32.4396 37.4966 30.5295 37.8632 28.6867C38.2298 26.8439 39.1346 25.1511 40.4632 23.8225C41.7918 22.4939 43.4845 21.5891 45.3273 21.2226C47.1701 20.856 49.0803 21.0442 50.8162 21.7632C52.5521 22.4822 54.0358 23.6999 55.0796 25.2621C56.1235 26.8244 56.6807 28.6611 56.6807 30.54C56.678 33.0588 55.6763 35.4736 53.8953 37.2546C52.1142 39.0357 49.6994 40.0374 47.1807 40.04V40.04ZM47.1807 23.04C45.6973 23.04 44.2473 23.4799 43.0139 24.304C41.7805 25.1281 40.8192 26.2995 40.2516 27.6699C39.6839 29.0404 39.5354 30.5484 39.8248 32.0032C40.1142 33.4581 40.8285 34.7945 41.8774 35.8433C42.9263 36.8922 44.2626 37.6065 45.7175 37.8959C47.1723 38.1853 48.6803 38.0368 50.0508 37.4691C51.4212 36.9015 52.5926 35.9402 53.4167 34.7068C54.2408 33.4735 54.6807 32.0234 54.6807 30.54C54.6807 28.5509 53.8905 26.6433 52.484 25.2367C51.0774 23.8302 49.1698 23.04 47.1807 23.04Z"
      fill="currentColor"
    />
    <path
      d="M47.1807 31.54C47.0062 31.539 46.8349 31.4923 46.6841 31.4046C46.5332 31.3169 46.4079 31.1912 46.3207 31.04L34.1007 10.04H28.6807C28.4154 10.04 28.1611 9.93468 27.9736 9.74715C27.786 9.55961 27.6807 9.30526 27.6807 9.04004C27.6807 8.77482 27.786 8.52047 27.9736 8.33293C28.1611 8.1454 28.4154 8.04004 28.6807 8.04004H34.6807C34.8552 8.04109 35.0264 8.0878 35.1772 8.17551C35.3281 8.26323 35.4534 8.3889 35.5407 8.54004L48.0407 30.04C48.1719 30.2685 48.2077 30.5394 48.1403 30.7941C48.0729 31.0487 47.9077 31.2665 47.6807 31.4C47.5292 31.4899 47.3568 31.5382 47.1807 31.54Z"
      fill="currentColor"
    />
    <path
      d="M43.6811 19.0398C43.6082 19.0502 43.5341 19.0502 43.4611 19.0398L39.1111 18.0398C38.978 18.0093 38.8525 17.9519 38.7425 17.8711C38.6324 17.7902 38.5401 17.6877 38.4711 17.5698L36.2111 13.6598C36.1234 13.5078 36.0771 13.3354 36.0771 13.1598C36.0771 12.9843 36.1234 12.8118 36.2111 12.6598C36.2924 12.5106 36.4103 12.3846 36.5538 12.2936C36.6972 12.2026 36.8615 12.1497 37.0311 12.1398L43.6811 11.7798C43.817 11.7737 43.9528 11.7954 44.08 11.8435C44.2072 11.8917 44.3233 11.9653 44.4211 12.0598C44.5186 12.1528 44.5963 12.2645 44.6496 12.3882C44.7029 12.5119 44.7306 12.6451 44.7311 12.7798V18.0398C44.7304 18.19 44.6959 18.3381 44.6301 18.4732C44.5643 18.6082 44.469 18.7267 44.3511 18.8198C44.1619 18.9721 43.9238 19.0503 43.6811 19.0398ZM39.9811 16.1598L42.6811 16.7798V13.8398L38.7611 14.0498L39.9811 16.1598Z"
      fill="currentColor"
    />
    <path
      d="M27.8307 35.0397H18.6807C18.4154 35.0397 18.1611 34.9343 17.9736 34.7468C17.786 34.5592 17.6807 34.3049 17.6807 34.0397C17.6807 33.7744 17.786 33.5201 17.9736 33.3326C18.1611 33.145 18.4154 33.0397 18.6807 33.0397H27.8307C28.2032 33.0358 28.5672 32.9279 28.8817 32.7283C29.1962 32.5286 29.4486 32.2451 29.6107 31.9097L34.7907 21.5697C34.8404 21.4391 34.917 21.3204 35.0154 21.2213C35.1138 21.1221 35.2319 21.0447 35.3621 20.994C35.4922 20.9433 35.6316 20.9204 35.7712 20.9268C35.9107 20.9333 36.0474 20.9689 36.1723 21.0314C36.2973 21.0938 36.4077 21.1818 36.4966 21.2896C36.5855 21.3974 36.6508 21.5226 36.6883 21.6571C36.7259 21.7917 36.7348 21.9326 36.7145 22.0709C36.6943 22.2091 36.6452 22.3415 36.5707 22.4597L31.4007 32.8297C31.0689 33.4927 30.5593 34.0505 29.9289 34.4407C29.2985 34.831 28.5721 35.0383 27.8307 35.0397V35.0397Z"
      fill="currentColor"
    />
    <path
      d="M27.7307 31.2002H22.1807C21.9154 31.2002 21.6611 31.0948 21.4736 30.9073C21.286 30.7198 21.1807 30.4654 21.1807 30.2002C21.1807 29.935 21.286 29.6806 21.4736 29.4931C21.6611 29.3056 21.9154 29.2002 22.1807 29.2002H27.7307C27.9959 29.2002 28.2502 29.3056 28.4378 29.4931C28.6253 29.6806 28.7307 29.935 28.7307 30.2002C28.7307 30.4654 28.6253 30.7198 28.4378 30.9073C28.2502 31.0948 27.9959 31.2002 27.7307 31.2002Z"
      fill="currentColor"
    />
    <path
      d="M28.9511 27.8496H23.4111C23.1459 27.8496 22.8916 27.7443 22.704 27.5567C22.5165 27.3692 22.4111 27.1148 22.4111 26.8496C22.4111 26.5844 22.5165 26.33 22.704 26.1425C22.8916 25.955 23.1459 25.8496 23.4111 25.8496H28.9511C29.2164 25.8496 29.4707 25.955 29.6582 26.1425C29.8458 26.33 29.9511 26.5844 29.9511 26.8496C29.9511 27.1148 29.8458 27.3692 29.6582 27.5567C29.4707 27.7443 29.2164 27.8496 28.9511 27.8496Z"
      fill="currentColor"
    />
    <path
      d="M24.0007 23.0401H3.68066C3.41545 23.0401 3.16109 22.9348 2.97356 22.7472C2.78602 22.5597 2.68066 22.3053 2.68066 22.0401C2.68066 18.8001 5.30066 15.0401 8.68066 15.0401H18.9407C19.1777 15.0373 19.4081 15.1188 19.5907 15.2701C19.8769 15.5719 20.0772 15.9448 20.1707 16.3501C20.1707 16.4601 20.2407 16.5701 20.2707 16.6701L25.0107 19.7301C25.1339 19.8098 25.2381 19.9156 25.3159 20.04C25.3936 20.1644 25.4431 20.3044 25.4607 20.4501C25.4607 20.7401 25.4607 20.9601 25.1607 21.7001C25.0907 21.9001 25.0207 22.1001 24.9907 22.2001C24.9526 22.435 24.832 22.6487 24.6505 22.8026C24.4691 22.9566 24.2386 23.0408 24.0007 23.0401V23.0401ZM4.79066 21.0401H23.2707L19.0807 18.3401C18.6598 18.0433 18.3697 17.5955 18.2707 17.0901H8.68066C6.84066 17.0401 5.22066 19.0501 4.79066 21.0401Z"
      fill="currentColor"
    />
    <path
      d="M34.3712 23.0401H24.0012C23.8434 23.04 23.6878 23.0025 23.5473 22.9308C23.4067 22.859 23.2851 22.755 23.1924 22.6272C23.0998 22.4994 23.0387 22.3515 23.0141 22.1956C22.9896 22.0397 23.0023 21.8802 23.0512 21.7301L23.2812 20.9901L19.0412 18.2501C18.8802 18.1463 18.7524 17.9985 18.673 17.8241C18.5935 17.6498 18.5659 17.4563 18.5933 17.2667C18.6207 17.077 18.702 16.8993 18.8275 16.7545C18.953 16.6098 19.1174 16.5041 19.3012 16.4501L32.8712 12.4501C33.6494 12.1276 34.4949 12.0008 35.3335 12.081C36.1721 12.1612 36.9783 12.4459 37.6812 12.9101C38.3411 13.482 38.8636 14.1952 39.2098 14.9968C39.5561 15.7984 39.7172 16.6677 39.6812 17.5401V17.6901C39.6865 18.3908 39.5531 19.0856 39.2886 19.7345C39.0241 20.3833 38.6338 20.9734 38.1402 21.4708C37.6466 21.9681 37.0594 22.3628 36.4126 22.6321C35.7657 22.9015 35.0719 23.0402 34.3712 23.0401V23.0401ZM25.3712 21.0401H34.3712C35.2491 21.0401 36.091 20.6914 36.7118 20.0707C37.3325 19.4499 37.6812 18.608 37.6812 17.7301V17.5801C37.706 17.0217 37.6098 16.4645 37.3992 15.9468C37.1886 15.429 36.8687 14.9628 36.4612 14.5801C36.009 14.3188 35.501 14.1689 34.9793 14.1428C34.4577 14.1167 33.9373 14.2152 33.4612 14.4301V14.4301L21.9612 17.7601L24.9612 19.7601C25.156 19.8723 25.3073 20.0467 25.3907 20.2553C25.4742 20.464 25.4849 20.6946 25.4212 20.9101L25.3712 21.0401Z"
      fill="currentColor"
    />
    <path
      d="M28.6807 6.17C28.4154 6.17 28.1611 6.06464 27.9736 5.87711C27.786 5.68957 27.6807 5.43522 27.6807 5.17V1.5C27.6807 1.23478 27.786 0.98043 27.9736 0.792893C28.1611 0.605357 28.4154 0.5 28.6807 0.5C28.9459 0.5 29.2002 0.605357 29.3878 0.792893C29.5753 0.98043 29.6807 1.23478 29.6807 1.5V5.17C29.6807 5.43522 29.5753 5.68957 29.3878 5.87711C29.2002 6.06464 28.9459 6.17 28.6807 6.17V6.17Z"
      fill="currentColor"
    />
    <path
      d="M32.4607 10.0402C32.2224 10.0436 31.9908 9.96194 31.8075 9.80977C31.6241 9.6576 31.5011 9.44498 31.4607 9.21018L30.5907 4.33018H28.6807C28.4154 4.33018 28.1611 4.22483 27.9736 4.03729C27.786 3.84976 27.6807 3.5954 27.6807 3.33018C27.6807 3.06497 27.786 2.81061 27.9736 2.62308C28.1611 2.43554 28.4154 2.33018 28.6807 2.33018H31.4407C31.6789 2.32672 31.9105 2.40843 32.0939 2.5606C32.2772 2.71276 32.4002 2.92539 32.4407 3.16018L33.4407 8.86018C33.4885 9.11428 33.4361 9.37705 33.2944 9.59333C33.1527 9.80961 32.9327 9.9626 32.6807 10.0202L32.4607 10.0402Z"
      fill="currentColor"
    />
    <path
      d="M9.68084 31.8197C9.57461 31.8334 9.46706 31.8334 9.36084 31.8197C9.23589 31.7779 9.12043 31.7119 9.02108 31.6254C8.92172 31.5389 8.84044 31.4336 8.7819 31.3156C8.72336 31.1976 8.68871 31.0692 8.67995 30.9377C8.67119 30.8063 8.68848 30.6744 8.73084 30.5497L11.5508 22.0897C11.5929 21.9649 11.659 21.8497 11.7456 21.7505C11.8322 21.6513 11.9374 21.5701 12.0554 21.5116C12.1733 21.4531 12.3016 21.4184 12.4329 21.4095C12.5643 21.4006 12.6961 21.4177 12.8208 21.4597C12.9456 21.5017 13.0609 21.5679 13.16 21.6544C13.2592 21.741 13.3404 21.8463 13.3989 21.9642C13.4574 22.0821 13.4921 22.2104 13.501 22.3418C13.5099 22.4731 13.4929 22.6049 13.4508 22.7297L10.6808 31.1297C10.613 31.3385 10.4784 31.5192 10.2976 31.644C10.1169 31.7687 9.90015 31.8304 9.68084 31.8197V31.8197Z"
      fill="currentColor"
    />
  </svg>
);

export default Motorycle;
