import React from "react";

const Location = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 22"
    fill="none"
    stroke="currentColor"
    width="100%"
    height="18"
  >
    <g id="pin_border" data-name="pin border" transform="translate(0.5 0.5)">
      <path
        id="Path_985"
        data-name="Path 985"
        d="M-72,107.36c0,6.531-8.5,13.64-8.5,13.64s-8.5-7.11-8.5-13.64c0-4.263,3.611-7.36,8.5-7.36S-72,103.1-72,107.36Z"
        transform="translate(89 -100)"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <circle
        id="Ellipse_240"
        data-name="Ellipse 240"
        cx="2.484"
        cy="2.484"
        r="2.484"
        transform="translate(6 5.511)"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default Location;
