import React from "react";

const ListSelect = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.24979 6.00014C8.24979 5.58593 8.58557 5.25014 8.99979 5.25014L19.9998 5.25014C20.414 5.25014 20.7498 5.58593 20.7498 6.00014C20.7498 6.41436 20.414 6.75014 19.9998 6.75014L8.99979 6.75014C8.58557 6.75014 8.24979 6.41435 8.24979 6.00014Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.51151 5.44156C5.81939 5.71865 5.84435 6.19287 5.56726 6.50075L5.55726 6.51186C5.28016 6.81975 4.80595 6.84471 4.49806 6.56761C4.19018 6.29052 4.16522 5.8163 4.44232 5.50842L4.45232 5.49731C4.72941 5.18942 5.20363 5.16447 5.51151 5.44156Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.51151 11.4416C5.81939 11.7187 5.84435 12.1929 5.56726 12.5008L5.55726 12.5119C5.28016 12.8197 4.80595 12.8447 4.49806 12.5676C4.19018 12.2905 4.16522 11.8163 4.44232 11.5084L4.45232 11.4973C4.72941 11.1894 5.20363 11.1645 5.51151 11.4416Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.26947 17.2698C3.56237 16.9769 4.03724 16.9769 4.33013 17.2698L4.5998 17.5395L6.06946 16.0698C6.36236 15.7769 6.83723 15.7769 7.13012 16.0698C7.42302 16.3627 7.42302 16.8376 7.13012 17.1305L5.13013 19.1305C4.83724 19.4233 4.36236 19.4233 4.06947 19.1305L3.26947 18.3305C2.97658 18.0376 2.97658 17.5627 3.26947 17.2698Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.24979 12.0001C8.24979 11.5859 8.58557 11.2501 8.99979 11.2501L19.9998 11.2501C20.414 11.2501 20.7498 11.5859 20.7498 12.0001C20.7498 12.4144 20.414 12.7501 19.9998 12.7501L8.99979 12.7501C8.58557 12.7501 8.24979 12.4144 8.24979 12.0001Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.24979 18.0001C8.24979 17.5859 8.58557 17.2501 8.99979 17.2501L19.9998 17.2501C20.414 17.2501 20.7498 17.5859 20.7498 18.0001C20.7498 18.4144 20.414 18.7501 19.9998 18.7501L8.99979 18.7501C8.58557 18.7501 8.24979 18.4144 8.24979 18.0001Z"
      fill="currentColor"
    />
  </svg>
);

export default ListSelect;
