import React from "react";

const LinkText = () => (
  <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.30005 5.99991C6.29711 6.13157 6.24336 6.25699 6.15005 6.34991L1.06005 11.4499C0.964898 11.5422 0.837575 11.5937 0.705048 11.5937C0.57252 11.5937 0.445198 11.5422 0.350048 11.4499C0.303184 11.4034 0.265986 11.3481 0.240602 11.2872C0.215217 11.2263 0.202148 11.1609 0.202148 11.0949C0.202148 11.0289 0.215217 10.9636 0.240602 10.9026C0.265986 10.8417 0.303184 10.7864 0.350048 10.7399L5.09005 5.99991L0.350048 1.25991C0.303183 1.21343 0.265985 1.15813 0.240601 1.0972C0.215217 1.03627 0.202148 0.970919 0.202148 0.904913C0.202148 0.838907 0.215217 0.773553 0.240601 0.712624C0.265985 0.651694 0.303183 0.596395 0.350048 0.549913C0.445198 0.457664 0.57252 0.406075 0.705047 0.406075C0.837574 0.406074 0.964898 0.457664 1.06005 0.549913L6.15005 5.64991C6.24336 5.74284 6.29711 5.86826 6.30005 5.99991Z"
      fill="#fff"/>
  </svg>

);

export default LinkText;
