import React from "react";

const MoreVertical = () => (
  <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9.35181" cy="3.00003" r="2.5" fill="#001450"/>
    <circle cx="9.35181" cy="10" r="2.5" fill="#001450"/>
    <circle cx="9.35181" cy="17" r="2.5" fill="#001450"/>
  </svg>

);

export default MoreVertical;
