import React from "react";

const LocationFilled = () => (
  <svg
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="18"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 22C9 22 0 15.4 0 8.8C0 3.3 4.5 0 9 0C13.5 0 18 3.3 18 8.8C18 15.4 9 22 9 22Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 12.1579C10.657 12.1579 12 10.862 12 9.26314C12 7.66429 10.657 6.36841 9 6.36841C7.343 6.36841 6 7.66429 6 9.26314C6 10.862 7.343 12.1579 9 12.1579V12.1579Z"
      fill="white"
    />
  </svg>
);

export default LocationFilled;
