import React from "react";

const Map = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9731 2.55173C20.8473 2.26034 21.75 2.911 21.75 3.83246V18.5675C21.75 19.1486 21.3782 19.6645 20.8269 19.8483L15.2372 21.7115C15.0832 21.7628 14.9168 21.7628 14.7628 21.7115L9 19.7906L4.02691 21.4483C3.15274 21.7397 2.25 21.089 2.25 20.1675L2.25 5.43246C2.25 4.85137 2.62183 4.33549 3.17309 4.15173L8.76283 2.28849C8.91678 2.23717 9.08322 2.23717 9.23717 2.28849L15 4.20943L19.9731 2.55173ZM14.25 5.54057L9.75 4.04057V18.4594L14.25 19.9594V5.54057ZM15.75 19.9594V5.54057L20.25 4.04057V18.4594L15.75 19.9594ZM8.25 18.4594V4.04057L3.75 5.54057V19.9594L8.25 18.4594Z"
      fill="currentColor"
    />
  </svg>
);

export default Map;
