import React from "react";

const Order = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M3.42537 6.50001C3.42537 6.15483 3.7052 5.87501 4.05037 5.87501L14.0504 5.875C14.3956 5.875 14.6754 6.15483 14.6754 6.5C14.6754 6.84518 14.3956 7.125 14.0504 7.125L4.05037 7.12501C3.7052 7.12501 3.42537 6.84518 3.42537 6.50001Z"
          fill="#001450"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M5.09204 9.83334C5.09204 9.48816 5.37186 9.20834 5.71704 9.20834L12.3837 9.20834C12.7289 9.20834 13.0087 9.48816 13.0087 9.83334C13.0087 10.1785 12.7289 10.4583 12.3837 10.4583L5.71704 10.4583C5.37186 10.4583 5.09204 10.1785 5.09204 9.83334Z"
          fill="#001450"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M6.75871 13.1667C6.75871 12.8215 7.03853 12.5417 7.38371 12.5417L10.717 12.5417C11.0622 12.5417 11.342 12.8215 11.342 13.1667C11.342 13.5118 11.0622 13.7917 10.717 13.7917H7.38371C7.03853 13.7917 6.75871 13.5119 6.75871 13.1667Z"
          fill="#001450"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M9.05037 1.29167C13.3076 1.29167 16.7587 4.74281 16.7587 9.00001C16.7587 13.2572 13.3076 16.7083 9.05037 16.7083C4.79318 16.7083 1.34204 13.2572 1.34204 9.00001C1.34204 4.74281 4.79318 1.29167 9.05037 1.29167ZM18.0087 9.00001C18.0087 4.05245 13.9979 0.0416718 9.05037 0.0416718C4.10282 0.0416718 0.092041 4.05245 0.092041 9.00001C0.092041 13.9476 4.10282 17.9583 9.05037 17.9583C13.9979 17.9583 18.0087 13.9476 18.0087 9.00001Z"
          fill="#001450"/>
  </svg>

);

export default Order;
